import * as moment from 'moment';
import convert from 'geo-coordinates-parser';
import { X2jOptionsOptional, XMLParser } from 'fast-xml-parser';
import { sha256 } from 'js-sha256';

import { plainToInstance } from 'class-transformer';
import { ValidatorFn } from '@angular/forms';
import { NODE_POSITIONS_COLORS } from '@constants/node-positions.colors';
import { NAMED_SERIES_COLORS } from '@constants/named-series-colors.colors';
import { NodeEntity } from '@entities/node.entity';
import { SensorEntity } from '@entities/sensor.entity';
import { ViewConfig } from '@config/view.config';
import { ToastrService } from 'ngx-toastr';
import {
  TranslationInstantParams,
  TranslationMWService,
} from '@services/translation-middleware/translation-middleware.service';
import { InstallationStructureEntity } from '@entities/installation-structure.entity';
import { SensorTypesConstants } from '@constants/sensor-types.constants';
import { SiloEntity } from '@entities/silo.entity';
import { BluetoothHubTypeConstants } from '@constants/bluetooth-hub-type.constants';
import { ProbeEntity } from '@entities/probe.entity';
import { BEDesktopService } from '@services/backend-services/desktop/be-desktop.service';
import { first } from 'rxjs/operators';
import prettyBytes from 'pretty-bytes';

export type StringIndexedEntities<T> = {
  [key: string]: T;
};

export type FormFieldValidatorsDef = {
  [key: string]: ValidatorFn | ValidatorFn[];
};

export type NodeSensorByType = {
  node: NodeEntity;
  sensor: SensorEntity;
};

export type IEntity<T> = new (...args: any[]) => T;

export const isUndefined = (value: any): value is undefined => typeof value === 'undefined';

export const isNull = (value: any): value is null => value === null;

export const isNumber = (value: any): value is number => typeof value === 'number';

export const isNumberFinite = (value: any): value is number => isNumber(value) && isFinite(value);

export const isPositive = (value: number): boolean => value >= 0;

export const isInteger = (value: number): boolean => value % 1 === 0;

export const isNil = (value: any): value is null | undefined =>
  value === null || typeof value === 'undefined';

export const isString = (value: any): value is string => typeof value === 'string';

export const isObject = (value: any): boolean => value !== null && typeof value === 'object';

export const isArray = (value: any): boolean => Array.isArray(value);

export const isFunction = (value: any): boolean => typeof value === 'function';

export const initializeClass = <T>(classType: IEntity<T>, data: Record<string, unknown> = {}) =>
  plainToInstance(classType, data, { excludeExtraneousValues: true });

export const generatePassword = (
  passwordLength = 12,
  includeLetters = true,
  includeNumbers = true,
  includeSymbols = false
): string => {
  const lowerCaseLetters = 'abcdefghijklmnopqrstuvwxyz';
  const upperCaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const numbers = '0123456789';
  const symbols = '!@#$%^&*()_-,.';
  let availableCharacters = '';

  if (includeLetters) {
    availableCharacters += lowerCaseLetters;
    availableCharacters += upperCaseLetters;
  }
  if (includeNumbers) {
    availableCharacters += numbers;
  }
  if (includeSymbols) {
    availableCharacters += symbols;
  }

  availableCharacters.split('');
  let generatedPassword = [];
  let notCorrect = false;
  while (!notCorrect) {
    generatedPassword = [];
    for (let i = 0; i < passwordLength; i += 1) {
      const max = availableCharacters.length;
      const ran = Math.random();
      const idx = Math.floor(ran * max);
      generatedPassword.push(availableCharacters[idx]);
    }

    if (
      (!includeNumbers || (includeNumbers && generatedPassword.find((p) => !isNaN(Number(p))))) &&
      (!includeLetters ||
        (includeLetters && generatedPassword.find((p: string) => p.toUpperCase() !== p))) &&
      (!includeSymbols ||
        (includeSymbols &&
          generatedPassword.find((p) => Object.prototype.toString.call(p) === '[object Symbol]')))
    ) {
      notCorrect = true;
    }
  }

  return generatedPassword.join('');
};

export const swapArrayItems = (arr: any[], ixdOne: number, idxTwo: number) =>
  ([arr[ixdOne], arr[idxTwo]] = [arr[idxTwo], arr[ixdOne]]);

export const secondsToHHMMSSLetters = (seconds: number) => {
  const formatTimeValue = (tv: number, unit: string) => (tv ? ''.concat(tv.toString(), unit) : '');

  const duration = moment.duration(seconds, 'seconds');
  const formatted = ''.concat(
    formatTimeValue(duration.days(), 'd'),
    formatTimeValue(duration.hours(), 'h'),
    formatTimeValue(duration.minutes(), 'm'),
    formatTimeValue(duration.seconds(), 's')
  );

  return formatted.length ? formatted : '0s';
};

export const secondsToMMSSColon = (seconds: number) => {
  const duration = moment.duration(seconds, 'seconds');
  const formatted = ''.concat(
    String(duration.minutes()).padStart(2, '0'),
    ':',
    String(duration.seconds()).padStart(2, '0')
  );

  return formatted.length ? formatted : '00:00';
};

const getNumberSeriesColor = (series: number): string => {
  const idxSeries: number = Math.min(NODE_POSITIONS_COLORS.length - 1, Math.max(0, series));
  return NODE_POSITIONS_COLORS[idxSeries];
};

const getNamedSeriesColor = (series: string): string =>
  NAMED_SERIES_COLORS[series] || NAMED_SERIES_COLORS.default;

export const getSeriesColor = (series: string): string =>
  !series
    ? NAMED_SERIES_COLORS.default
    : isNaN(+series)
    ? getNamedSeriesColor(String(series))
    : getNumberSeriesColor(Number(series));

export const timezoneBoundDate = (date: string): string =>
  moment.utc(date).subtract(ViewConfig.TIMEZONE_OFFSET, 'minutes').toISOString().slice(0, 19);

export const offsetDate = (date: string): Date => moment.utc(date).toDate();

export const utcDate = (date: string): string => moment.utc(date).toISOString().slice(0, 19);

export const startOfDay = (date: string): string =>
  moment(date).startOf('day').toISOString(true).slice(0, 19);

export const endOfDay = (date: string): string =>
  moment(date).endOf('day').toISOString(true).slice(0, 19);

export const startOfMonth = (date: string): string =>
  moment(date).startOf('month').toISOString().slice(0, 19);

export const endOfMonth = (date: string): string =>
  moment(date).endOf('month').toISOString().slice(0, 19);

export const utcAddDate = (
  date: string,
  forward: number,
  unitOfTime: moment.unitOfTime.Base = 'days'
): string => moment.utc(date).add(forward, unitOfTime).toISOString().slice(0, 19);

export const utcSubtractDate = (
  date: string,
  backward: number,
  unitOfTime: moment.unitOfTime.Base = 'days'
): string => moment.utc(date).subtract(backward, unitOfTime).toISOString().slice(0, 19);

export const utcDateToISOString = (date: Date): string =>
  moment.utc(date.toString().slice(0, 24)).toISOString().slice(0, 19);

export const utcNow = (): string => moment.utc().toDate().toISOString().slice(0, 19);

export const formattedCoordinates = (coordinates: string): string =>
  convert(coordinates, 6).toCoordinateFormat(convert.to.DMS);

export const onNullIsland = (coordinates: string): boolean =>
  coordinates.replace(' ', '') === '0,0';

export const getContrastYIQ = (hexcolor: string) => {
  // If a leading # is provided, remove it
  if (hexcolor.slice(0, 1) === '#') {
    hexcolor = hexcolor.slice(1);
  }
  // If a three-character hexcode, make six-character
  if (hexcolor.length === 3) {
    hexcolor = hexcolor
      .split('')
      .map((hex: string) => hex + hex)
      .join('');
  }
  // Convert to RGB value
  const r = parseInt(hexcolor.slice(0, 2), 16);
  const g = parseInt(hexcolor.slice(2, 4), 16);
  const b = parseInt(hexcolor.slice(4, 6), 16);
  // Get YIQ ratio
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  // Check contrast
  return yiq >= 128 ? 'dark' : 'light';
};

export const downloadFile = (blob: Blob, fileName: string) => {
  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  const nav = window.navigator as any;
  if (nav && nav.msSaveOrOpenBlob) {
    // download PDF in IE
    nav.msSaveOrOpenBlob(blob, fileName);
  } else {
    const link = document.createElement('a');
    document.body.appendChild(link);
    const dataURL = window.URL.createObjectURL(blob);

    link.href = dataURL;
    link.download = fileName;
    link.click();

    setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(dataURL);
      link.remove();
    }, 100);
  }
};

export const downloadTextFile = (
  contents: string | Blob | BlobPart,
  fileName: string,
  contentType: string
) => {
  const blob = new Blob([contents], {
    type: contentType,
  });
  downloadFile(blob, fileName);
};

export const base64toBlob = (base64String: string, contentType: string) => {
  contentType = contentType || '';
  const sliceSize = 512;

  const byteCharacters = atob(base64String);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
};

export const downloadPDFFile = (base64String: string, fileName: string) => {
  const blob: Blob = base64toBlob(base64String, 'application/pdf');

  downloadFile(blob, fileName);
};

export const downloadExcelFile = (buffer: BlobPart, fileName: string): void =>
  downloadTextFile(
    buffer,
    fileName,
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  );

export const create256Hash = (content: string) => sha256.update(content).hex();

export const naiveRound = (num: number, decimalPlaces = 0) => {
  const p = Math.pow(10, decimalPlaces);
  return Math.round(num * p) / p;
};

const numberIntlFormatter = (
  language: string,
  minimumFractionDigits: number = 1,
  maximumFractionDigits: number = 1
) =>
  new Intl.NumberFormat(language, {
    minimumFractionDigits,
    maximumFractionDigits,
  });

export const formattedNumber = (
  language: string,
  value: number,
  minimumFractionDigits: number = 1,
  maximumFractionDigits: number = 1
) => numberIntlFormatter(language, minimumFractionDigits, maximumFractionDigits).format(value);

const dateTimeIntlFormatter = (language: string) =>
  new Intl.DateTimeFormat(language, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });

const dateIntlFormatter = (language: string) =>
  new Intl.DateTimeFormat(language, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });

export const dateIntlFormatForDX = (language: string) => {
  const year = 2222;
  const month = 12;
  const day = 15;
  const date = new Date(year, month - 1, day);

  return dateIntlFormatter(language)
    .format(date)
    .replace(`${year}`, 'yyyy')
    .replace(`${month}`, 'MM')
    .replace(`${day}`, 'dd');
};

const timeIntlFormatter = (language: string) =>
  new Intl.DateTimeFormat(language, {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });

const timeIntlFormatterUTC = (language: string) =>
  new Intl.DateTimeFormat(language, {
    timeZone: 'UTC',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });

export const formattedDateTime = (language: string, date: Date) =>
  dateTimeIntlFormatter(language).format(date).replace(',', '');

export const formattedDate = (language: string, date: Date) =>
  dateIntlFormatter(language).format(date).replace(',', '');

export const formattedTime = (language: string, date: Date) =>
  timeIntlFormatter(language).format(date).replace(',', '');

export const formattedTimeUTC = (language: string, date: Date) =>
  timeIntlFormatterUTC(language).format(date).replace(',', '');

export const celsiusToFahrenheit = (celsius: number) => naiveRound(celsius * (9 / 5) + 32, 1);

export const handleFileInput = (
  file: FileList,
  acceptedExtension: string,
  readMode: 'URL' | 'TEXT',
  toastr: ToastrService,
  translationMWService: TranslationMWService,
  onLoadCallback: (fileToUpload: File) => (event: any) => void
): void => {
  if (!file.item(0)) {
    return;
  }

  const fileToUpload = file.item(0);
  const fileExt = (fileToUpload.name as string).split('.').pop().toLowerCase();
  if (fileExt === acceptedExtension) {
    const reader = new FileReader();
    reader.onload = onLoadCallback(fileToUpload);
    if (readMode === 'URL') {
      reader.readAsDataURL(fileToUpload);
    }
    if (readMode === 'TEXT') {
      reader.readAsText(fileToUpload);
    }
  } else {
    toastr.error(
      translationMWService.instant('form.upload.toastr.unaccepted-extension.message', {
        fileExt,
      }),
      translationMWService.instant('form.upload.toastr.unaccepted-extension.title')
    );
  }
};

export type XMLParsingResult = {
  name: string;
  reference: string;
  structure: InstallationStructureEntity;
};

export const parseConfigurationXML = (xmlContents: string): XMLParsingResult => {
  const xmlParsingResult: XMLParsingResult = {
    name: '',
    reference: '',
    structure: new InstallationStructureEntity(),
  };

  try {
    const options: X2jOptionsOptional = {
      ignoreAttributes: false,
      attributeNamePrefix: '',
      allowBooleanAttributes: true,
    };
    const parser = new XMLParser(options);
    const xmlAsJSONObj = parser.parse(xmlContents);
    const installation = xmlAsJSONObj.Installation;
    if (!installation.isCTCLite || installation.isCTCLite !== 'True') {
      throw new TranslationInstantParams('installation.entity.xml-parser.its-not-ctc-lite');
    }
    if (installation.ReferenceName && installation.ReferenceName.length) {
      xmlParsingResult.reference = installation.ReferenceName;
    } else {
      throw new TranslationInstantParams('installation.entity.xml-parser.no-reference');
    }
    const offsetList =
      installation.OffsetList && installation.OffsetList.Offset
        ? installation.OffsetList.Offset
        : [];
    xmlParsingResult.name = installation.Name;
    if (installation.Zone) {
      xmlParsingResult.structure.silos = [];
      if (Array.isArray(installation.Zone)) {
        for (const zone of installation.Zone) {
          const newSilo = extractSiloFromXML(zone, offsetList);
          xmlParsingResult.structure.silos.push(newSilo);
        }
      } else {
        const newSilo = extractSiloFromXML(installation.Zone, offsetList);
        xmlParsingResult.structure.silos.push(newSilo);
      }
    } else {
      throw new TranslationInstantParams('installation.entity.xml-parser.no-zones');
    }
  } catch (err) {
    throw err;
    // } finally {
  }
  return xmlParsingResult;
};

const extractSiloFromXML = (zone: any, offsetList: any[]) => {
  const newSilo = new SiloEntity();
  newSilo.name = zone.Name;
  if (!zone.HubType) {
    throw new TranslationInstantParams('installation.entity.xml-parser.no-hub-type', {
      zone: zone.Name,
    });
  }
  const hubTypeConstant = new BluetoothHubTypeConstants().findByName(zone.HubType);
  if (hubTypeConstant) {
    newSilo.hubType = hubTypeConstant.value;
  } else {
    throw new TranslationInstantParams('installation.entity.xml-parser.wrong-hub-type-value', {
      value: zone.HubType,
      zone: zone.Name,
    });
  }
  if (!zone.HubId) {
    throw new TranslationInstantParams('installation.entity.xml-parser.no-hub-id', {
      zone: zone.Name,
    });
  }
  newSilo.hubId = zone.HubId;
  if (zone.Line) {
    newSilo.probes = [];
    if (Array.isArray(zone.Line)) {
      for (const line of zone.Line) {
        const newProbe = extractProbeFromXML(line, offsetList);
        newSilo.probes.push(newProbe);
      }
    } else {
      const newProbe = extractProbeFromXML(zone.Line, offsetList);
      newSilo.probes.push(newProbe);
    }
  } else {
    throw new TranslationInstantParams('installation.entity.xml-parser.no-lines-for-zone', {
      zone: zone.Name,
    });
  }

  return newSilo;
};

const extractProbeFromXML = (line: any, offsetList: any[]) => {
  const newProbe = new ProbeEntity();
  newProbe.name = line.Name;
  newProbe.isTwoWires = line.IsTwoWires === 'True';
  newProbe.isAnalogic = line.IsAnalogic === 'True';
  newProbe.twoWiresHasHum = line.TwoWiresHasHum === 'True';
  if (line.IsJavelin && line.IsJavelin === 'True') {
    newProbe.batteryCapacity = Number(line.Battery);
  }
  const sensors = newProbe.isAnalogic ? line.Sensor : line.Node;
  let position = 1;
  if (sensors) {
    const newNodes: NodeEntity[] = [];
    if (Array.isArray(sensors)) {
      for (const sensor of sensors) {
        newNodes.push(extractNodeFromXML(sensor, position++, newProbe, offsetList));
      }
    } else {
      newNodes.push(extractNodeFromXML(sensors, position, newProbe, offsetList));
    }
    if (newProbe.isTwoWires) {
      newProbe.twoWiresNodes = newNodes;
    } else {
      newProbe.multiplexerNodes = newNodes;
    }
  } else {
    if (newProbe.isAnalogic) {
      throw new TranslationInstantParams('installation.entity.xml-parser.no-sensors-for-line', {
        line: line.Name,
      });
    } else {
      throw new TranslationInstantParams('installation.entity.xml-parser.no-nodes-for-line', {
        line: line.Name,
      });
    }
  }

  return newProbe;
};

const extractNodeFromXML = (sensor: any, position: number, parentProbe: any, offsetList: any[]) => {
  const newNode = new NodeEntity();
  newNode.position = position;
  if (parentProbe.isTwoWires) {
    newNode.codeH = sensor.CodiH;
    newNode.codeL = sensor.CodiL;
  } else {
    newNode.connectionCodeMultiplexer = sensor.ConectionCodeMultiplexor;
    newNode.connectionPointInMultiplexer = sensor.ConectionPointInMultiplexor;
    newNode.level = sensor.Level;
    matchNApplyOffset(newNode, offsetList);
  }
  newNode.sensors = [];
  // always add a temperature sensor
  newNode.sensors.push(
    initializeClass(SensorEntity, {
      sensorType: SensorTypesConstants.TEMPERATURE_C.value,
    })
  );
  // if defined, add a humidity sensor
  if (
    (!parentProbe.isAnalogic && sensor.HasHum === 'True') ||
    (parentProbe.isTwoWires && parentProbe.twoWiresHasHum)
  ) {
    newNode.sensors.push(
      initializeClass(SensorEntity, {
        sensorType: SensorTypesConstants.HUMIDITY.value,
      })
    );
  }
  // if defined, add a CO2 sensor
  if (!parentProbe.isAnalogic && sensor.HasCO2) {
    newNode.sensors.push(
      initializeClass(SensorEntity, {
        sensorType: SensorTypesConstants.CO2.value,
      })
    );
  }

  return newNode;
};

const matchNApplyOffset = (node: NodeEntity, offsetList: any[]) => {
  if (!offsetList || !offsetList.length) return;
  offsetList.forEach((offset: any) => {
    if (
      offset.MultiplexorCode === node.connectionCodeMultiplexer &&
      offset.MultiplexorPort === node.connectionPointInMultiplexer
    ) {
      node.csMultiplier = Number(offset.CS);
      node.coOffsetValue = Number(offset.Value);
    }
  });
};

export const downloadEncryptedReference = (
  beDesktopService: BEDesktopService,
  reference: string
) => {
  let decryptedCode = reference.replace(/[^a-zA-Z0-9]/g, '');
  decryptedCode = decryptedCode.slice(0, 5) + decryptedCode.slice(-5);

  beDesktopService
    .getEncryptedCode(decryptedCode)
    .pipe(first())
    .subscribe((response) => {
      downloadTextFile(response.encrypted, 'registration-confirmation-code.txt', 'text/plain');
    });
};

export const buildChecksum = (input: string): string => {
  const encoder = new TextEncoder();
  const bytes = encoder.encode(input);

  let result = bytes.reduce((sum, value) => sum + value, 0);

  result *= result;
  // eslint-disable-next-line no-bitwise
  result &= 0xffff;

  const checkSum = result.toString(16).padStart(4, '0').toUpperCase();
  // DEBUG output
  // console.log('Checksum: ', checkSum);
  return checkSum;
};

export const envFileToObject = (envFileContents: string): StringIndexedEntities<string> => {
  const env: StringIndexedEntities<string> = {};

  envFileContents.replace(/(\w+)=(.+)/g, ($0, $1, $2) => (env[$1] = $2 ? $2 : ''));

  return env;
};

export const customDataSummary = (dataSummary: number, language: string = 'en'): string => {
  const customValue =
    dataSummary < 1000
      ? 1000
      : dataSummary < 1000000
      ? dataSummary - (dataSummary % 1000)
      : dataSummary;
  return prettyBytes(customValue, {
    binary: false,
    bits: false,
    maximumFractionDigits: 1,
    locale: language,
  });
};

export const urlSafeBase64Encode = (plain: string): string => {
  try {
    // Encode the input string to Base64
    let base64 = btoa(plain);
    // Replace Base64 characters to make it URL-safe
    base64 = base64.replace(/\+/g, '-').replace(/\//g, '_');
    // Remove any trailing '=' characters
    return base64.replace(/=+$/, '');
  } catch (error) {
    // Catch and handle the error
    console.error('Encoding error:', error);
    return undefined;
  }
};

export const urlSafeBase64Decode = (encoded: string): string => {
  try {
    // Replace URL-safe characters
    let base64 = encoded.replace(/-/g, '+').replace(/_/g, '/');
    // Add padding if necessary
    const padding = base64.length % 4;
    if (padding) {
      base64 += '='.repeat(4 - padding);
    }
    // Decode the Base64 string
    return atob(base64);
  } catch (error) {
    // Catch and handle the error
    console.error('Decoding error:', error);
    return undefined;
  }
};
